import Layout from '@layouts';
import { NOOP } from '@lib';
import { useFormik } from 'formik';
import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import BlogPostPreview from '@components/blog/BlogPostPreview';
import SelectInput from '@components/form/SelectInput';

import { BlogPost, BlogPostTag } from '@data/blog';

type BlogPageProps = {
  data: {
    blogPosts: {
      edges: { node: BlogPost }[];
    };
    blogTags: {
      edges: { node: BlogPostTag }[];
    };
  };
};

const ALL_TAGS_VALUE = {
  value: 'ALL',
  label: 'All Topics',
  buttonLabel: 'All Topics',
};

const mapBlogTagToSelectValue = (tag: BlogPostTag) => ({
  value: tag.id,
  label: tag.name,
  buttonLabel: tag.name,
});

const BlogPage: FC<BlogPageProps> = ({ data: { blogPosts, blogTags } }) => {
  const [tagFilter, setTagFilter] = useQueryParam('tag', StringParam);

  const filteredBlogPosts = blogPosts.edges.filter(
    ({ node }) => !tagFilter || node.tags.some((tag) => tag.id === tagFilter)
  );

  const formik = useFormik({
    initialValues: {
      tagFilter: tagFilter
        ? mapBlogTagToSelectValue(
            blogTags.edges.find((it) => it.node.id === tagFilter).node
          )
        : ALL_TAGS_VALUE,
    },
    onSubmit: NOOP,
  });

  useEffect(() => {
    setTagFilter(
      formik.values.tagFilter.value === 'ALL'
        ? undefined
        : formik.values.tagFilter.value
    );
  }, [formik.values.tagFilter]);

  return (
    <Layout url="/media/" title="Media">
      <div className="hidden lg:flex mt-7 border-b border-background-light pb-1.5 -mx-5 px-5">
        <Link
          className="text-sm opacity-70 font-emp no-underline"
          to={`/media/`}
        >
          All topics
        </Link>
        {blogTags.edges.map((tag) => {
          return (
            <Link
              className={'pl-8 text-sm opacity-70 font-emp no-underline'}
              key={tag.node.id}
              to={`/media/?tag=${tag.node.id}`}
            >
              {tag.node.name}
            </Link>
          );
        })}
      </div>

      <p className="lg:hidden font-emp font-bold text-base text-center mt-4 mb-8">
        Media
      </p>

      <SelectInput
        formik={formik}
        className="lg:hidden"
        name="tagFilter"
        values={[
          ALL_TAGS_VALUE,
          ...blogTags.edges.map((it) => mapBlogTagToSelectValue(it.node)),
        ]}
      />

      {!filteredBlogPosts.length && (
        <div className="mt-5 opacity-70">
          There are no articles here. Please check back soon!
        </div>
      )}

      <div className="grid grid-cols-1 gap-6 mb-6 mt-5">
        {filteredBlogPosts
          .filter((feat) => feat.node.featured)
          .map(({ node }) => (
            <BlogPostPreview key={node.id} post={node} featuredBlog />
          ))}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-20">
        {filteredBlogPosts
          .filter((feat) => !feat.node.featured)
          .map(({ node }) => (
            <BlogPostPreview key={node.id} post={node} />
          ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogQuery {
    blogPosts: allGhostPost(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          id
          excerpt
          reading_time
          title
          slug
          published_at
          featured
          primary_tag {
            name
          }
          primary_author {
            name
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                quality: 90
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          tags {
            id
            name
          }
        }
      }
    }
    blogTags: allGhostTag {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default BlogPage;
